<template>
  <v-data-table
    :headers="headers"
    :items="arPaymentMethods"
    :items-per-page="-1"
    class="dt-listing transparent"
    disable-pagination
    disable-sort
    hide-default-footer
  >
    <!-- <template #top>
      <v-toolbar color="transparent" flat>
        <v-toolbar-title v-text="paymentMethod.name" />
        <v-spacer />
      </v-toolbar>
    </template> -->

    <template #item="{ item, index, headers }">
      <invoice-payment-methods-row
        :currency-ids="currencyIds"
        :headers="headers"
        :index="index"
        :items="headerKeyList"
        :max-value="balance"
        :open="open"
        :payment-method="paymentMethod"
        :value="item"
        @delete="removePaymentMethod"
        @update:item="onUpdateField"
      />
    </template>
  </v-data-table>
</template>

<script lang="ts">
import { Component, Mixins, Prop } from "vue-property-decorator";
import InvoiceMixin from "@/modules/invoices/mixins/InvoiceMixin";
import DataTableMixin, { DataTableHeader } from "@/mixins/DataTableMixin";
import {
  InvoicePaymentMethodData,
  PaymentMethod,
  PaymentMethodData,
} from "@planetadeleste/vue-mc-gw";
import InvoicePaymentMethodsRow from "@/modules/invoices/components/paymentmethod/InvoicePaymentMethodsRow.vue";
import {
  getTableHeaders,
  InvoicePaymentMethodCode,
} from "@/config/invoicePaymentMethods";
import { each, filter, get, map, set, union } from "lodash";

@Component({
  components: { InvoicePaymentMethodsRow },
})
export default class InvoicePaymentMethodsTable extends Mixins(
  DataTableMixin,
  InvoiceMixin
) {
  @Prop(Object) readonly paymentMethod!: PaymentMethod;
  @Prop(Array) readonly companyPaymentMethods!: PaymentMethodData[];
  @Prop(Array) readonly currencyIdList!: number[];
  @Prop(Number) readonly balance!: number;
  @Prop(Boolean) readonly open!: boolean;

  headers: DataTableHeader[] = [
    { text: "amount", value: "amount", sortable: false, class: "w-min-150" },
    {
      text: "currency",
      value: "currency_id",
      sortable: false,
      class: "w-min-150",
    },
    { text: "rate", value: "rate", sortable: false, class: "w-min-150" },
  ];

  get type(): InvoicePaymentMethodCode | null {
    // @ts-ignore
    return this.paymentMethod ? this.paymentMethod.code : null;
  }

  get arPaymentMethods(): InvoicePaymentMethodData[] {
    const arInvoicePaymentMethods = this.obInvoice.get("payment_methods", []);
    return filter(arInvoicePaymentMethods, (obModel) => {
      return obModel.payment_method_id == this.paymentMethod.id;
    });
  }

  /**
   * Set Invoice payment_methods based on current PaymentMethod.id
   */
  set arPaymentMethods(arValues: InvoicePaymentMethodData[]) {
    const arInvoicePaymentMethods: InvoicePaymentMethodData[] =
      this.obInvoice.get("payment_methods", []);
    const arIndexList: number[] = [];

    each(arInvoicePaymentMethods, (obValue, iIndex) => {
      // Convert model to plain object
      if (obValue.payment_method instanceof PaymentMethod) {
        set(obValue, "payment_method", obValue.payment_method.attributes);
      }

      if (obValue.payment_method_id == this.paymentMethod.id) {
        arIndexList.push(iIndex);
      }
    });

    if (!arIndexList.length) {
      this.obInvoice.set(
        "payment_methods",
        union(arInvoicePaymentMethods, arValues)
      );
    } else {
      arIndexList.forEach((iIndex, idx) => {
        if (arValues[idx] === undefined) {
          arInvoicePaymentMethods.splice(iIndex, 1);
          return;
        }

        arInvoicePaymentMethods.splice(iIndex, 1, arValues[idx]);
      });

      this.obInvoice.set("payment_methods", arInvoicePaymentMethods);
    }
  }

  get currencyIds(): number[] {
    const customerCurrencyIdList: number[] =
      !!this.customer && this.customer.currencies
        ? map(this.customer.currencies, "id")
        : [];

    return this.currencyIdList &&
      this.currencyIdList.length &&
      customerCurrencyIdList.length
      ? filter(customerCurrencyIdList, (id) => this.currencyIdList.includes(id))
      : customerCurrencyIdList;
  }

  get headerKeyList(): string[] {
    return map(this.headers, "value");
  }

  mounted() {
    this.$nextTick(() => {
      this.setHeaders();
      this.addDTActionsHeader();
      this.mapDTHeaders();
    });
  }

  onUpdateField(arArgs: [number, string, any]) {
    const index = arArgs[0];
    const sKey = arArgs[1];
    const sValue = arArgs[2];

    this.setPaymentMethod(sKey, sValue, index);
  }

  setPaymentMethod(sKey: string, sValue: any, iIndex = 0) {
    const arInvoicePaymentMethods = [...this.arPaymentMethods];
    const obInvoicePaymentMethod = get(arInvoicePaymentMethods, iIndex);
    if (!obInvoicePaymentMethod) {
      return;
    }

    set(obInvoicePaymentMethod, sKey, sValue);
    arInvoicePaymentMethods.splice(iIndex, 1, obInvoicePaymentMethod);
    this.arPaymentMethods = arInvoicePaymentMethods;
  }

  removePaymentMethod(iIndex: number) {
    const arInvoicePaymentMethods = [...this.arPaymentMethods];
    if (arInvoicePaymentMethods[iIndex] === undefined) {
      return;
    }

    arInvoicePaymentMethods.splice(iIndex, 1);
    this.arPaymentMethods = arInvoicePaymentMethods;
  }

  setHeaders() {
    if (!this.type) {
      return;
    }

    const arTableHeaders = getTableHeaders(this.type, (arKeys: string[]) => {
      if (this.canUpdateAccountingCode) {
        if (this.type === "CUENTA_BANCARIA") {
          return ["account_code"];
        }
        arKeys.push("account_code");
      }

      return arKeys;
    });

    this.headers = [...this.headers, ...arTableHeaders];
  }
}
</script>
